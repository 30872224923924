html {
  display: table;
  margin: auto;
}

body {
  max-width: 1400px;
  vertical-align: middle;
}

.center-image{
  display:flex;
  justify-content:center; /* horizontally center */
  align-items:center;    /* vertically center */
  height:100%;
  width:100%;
}

.center-image img{
 max-width:100%;
 max-height:100%;
}

img {
  max-width: 70%;
}

.search-bar{
  width: 20vw;
  margin-left: 10px;
}